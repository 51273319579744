/* eslint no-underscore-dangle: off */
import {
  ApolloClient,
  InMemoryCache,
  DataProxy,
  TypePolicies,
} from '@apollo/client';
import {BatchHttpLink} from '@apollo/client/link/batch-http';
import {getEnv} from 'client/utils/env';
import fetch from 'client/fetch';
import schemaToPossibleTypes from '@pollex/utils/introspection-schema-to-possible-types';
import some from 'lodash/some';

import ifDevElse from 'client/utils/if-dev-else';

const HANDS_RU_GRAPHQL_ENDPOINT_CLIENT =
  getEnv().HANDS_RU_GRAPHQL_ENDPOINT_CLIENT || '/graphql';

export function initApolloClient(): ApolloClient<any> {
  return new ApolloClient({
    connectToDevTools: true,
    link: new BatchHttpLink({
      uri: `${HANDS_RU_GRAPHQL_ENDPOINT_CLIENT}/batch`,
      fetch,
      batchInterval: 50,
      credentials: ifDevElse('include', 'same-origin'),
      batchKey: operation => {
        if (
          some(
            [
              'handsRuGetCatalogServicePage',
              'handsRuGetObjectActions',
              'handsRuGetCategoryObjects',
            ].map(key => operation.operationName.startsWith(key)),
          )
        ) {
          return 'eager';
        }

        return 'default';
      },
    }),
    queryDeduplication: true,
    cache: initCache(),
  });
}

export function tryReadFragment(
  client: ApolloClient<any>,
): ApolloClient<any>['readFragment'] {
  return function read<T>(
    ...args: [DataProxy.Fragment<any, T>, boolean]
  ): any | null {
    try {
      return client.readFragment<T>(...args);
    } catch (e) {
      return null;
    }
  };
}

export function getTypePolicy(): TypePolicies {
  return {
    Query: {
      fields: {
        catalog: {
          merge: true,
        },
        stats: {
          merge: true,
        },
        order: {
          keyArgs: ['hash'],
          merge: true,
        },
        reviewSlice: {
          merge: true,
        },
      },
    },
    ServiceType: {
      fields: {
        details: {
          merge: true,
        },
        price: {
          merge: true,
        },
        minimumPrice: {
          merge: true,
        },
      },
    },
    ServicePageType: {
      fields: {
        parentPage: {
          merge: true,
        },

        service: {
          merge: true,
        },
      },
    },
    ObjectPageType: {
      merge: true,
      fields: {
        parentPage: {
          merge: true,
        },
      },
    },
    ImageType: {
      keyFields: ['file'],
      merge: true,
    },
    OrderType: {
      merge: true,
    },
  };
}

function initCache(): InMemoryCache {
  const {
    // @ts-ignore
    __APOLLO_STATE__: state,
    // @ts-ignore
    __APOLLO_SCHEMA__: schema,
  } = window;

  const cache = new InMemoryCache({
    possibleTypes: schemaToPossibleTypes(schema),
    typePolicies: getTypePolicy(),
  });

  if (state) cache.restore(state);

  return cache;
}
