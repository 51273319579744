import React, {
  useContext,
  ReactNode,
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {useQuery, useMutation} from '@apollo/client';
import noop from 'lodash/noop';
import handsRuGetCart, {
  HandsRuGetCartData,
} from 'query-wrappers/HandsRuGetCart';
import handsRuDeleteCartitem, {
  HandsRuDeleteCartItemData,
  HandsRuDeleteCartItemVariables,
} from 'query-wrappers/HandsRuDeleteCartItem';
import handsRuAddCartitem, {
  HandsRuAddCartItemData,
  HandsRuAddCartItemVariables,
} from 'query-wrappers/HandsRuAddCartItem';
import {ServiceSlugType} from 'client/types/slug';
import {HandsRuMutateWizardVariables} from 'query-wrappers/HandsRuMutateWizard';
import {PriceType} from 'client/types/price';
import {EMPTY_PRICE} from 'client/utils/price';
import {CartBag, CartStateType} from './types';
import {useLocation} from '../Location';

export * from './types';

interface OwnProps {
  children: ReactNode;
}

export const DEFAULT_STATE: CartStateType = {
  entries: [],
  suggestions: [],
  modalService: null,
  totalPrice: 0,
  loading: false,
  cartItemToDelete: null,
  prepaySum: '0',
  minimumPrice: EMPTY_PRICE,
};

const Context = React.createContext<CartBag>({
  ...DEFAULT_STATE,
  deleteItem: noop,
  addItem: () => new Promise<any>(() => null),
  setModalService: () => new Promise<any>(() => null),
  refetch: () => new Promise<any>(() => null),
});

const CartProvider: FunctionComponent<OwnProps> = ({children}: OwnProps) => {
  const {screenName} = useLocation();
  const {data, loading: cartLoading, refetch} = useQuery<HandsRuGetCartData>(
    handsRuGetCart,
    {notifyOnNetworkStatusChange: true, ssr: false},
  );
  const [mutateDelete, {loading: deleteIsLoading}] = useMutation<
    HandsRuDeleteCartItemData,
    HandsRuDeleteCartItemVariables
  >(handsRuDeleteCartitem);
  const [mutateAdd, {loading: addIsLoading}] = useMutation<
    HandsRuAddCartItemData,
    HandsRuAddCartItemVariables
  >(handsRuAddCartitem);
  const [modalService, _setModalService] = useState<ServiceSlugType | null>(
    null,
  );
  const [
    cartItemToDelete,
    setCartItemToDelete,
  ] = useState<ServiceSlugType | null>(null);

  const entries = data?.cart?.entries || [];

  useEffect(() => {
    if (['main', 'order_success', 'client_orders'].includes(screenName)) {
      refetch();
    }
  }, [screenName]);

  const deleteItem = useCallback((serviceSlug: string) => {
    setCartItemToDelete(serviceSlug);
    mutateDelete({
      variables: {serviceSlug},
      update(cache, {data: mutationData}) {
        if (mutationData?.deleteCartItem.cart) {
          cache.writeQuery<HandsRuGetCartData>({
            query: handsRuGetCart,
            data: {cart: mutationData?.deleteCartItem.cart},
          });
        }
      },
    }).finally(() => setCartItemToDelete(null));
  }, []);

  const addItem = useCallback(
    async (
      serviceSlug: string,
      input?: HandsRuMutateWizardVariables['input'],
    ) => {
      const variables: HandsRuAddCartItemVariables = {serviceSlug};
      if (input) {
        variables.input = input;
      }

      return mutateAdd({
        variables,
        update(cache, {data: mutationData}) {
          if (mutationData?.addCartItem.cart) {
            cache.writeQuery<HandsRuGetCartData>({
              query: handsRuGetCart,
              data: {cart: mutationData?.addCartItem.cart},
            });
          }
        },
      });
    },
    [entries],
  );

  const setModalService = useCallback(async (serviceSlug, input) => {
    _setModalService(serviceSlug);
    if (serviceSlug) {
      return addItem(serviceSlug, input);
    }

    return new Promise(() => null);
  }, []);

  const loading = cartLoading || deleteIsLoading || addIsLoading;

  const minimumPrice = entries.reduce<PriceType>((s, e) => {
    const price = e.service.minimumPrice;
    if (s.amount < price.amount) {
      return price;
    }
    return s;
  }, EMPTY_PRICE);

  return (
    <Context.Provider
      value={{
        loading,
        entries,
        prepaySum: data?.cart?.prepaySum || '0',
        suggestions: data?.cart?.recommendations || [],
        totalPrice: data?.cart?.totalPrice.amount || 0,
        modalService,
        deleteItem,
        addItem,
        setModalService,
        refetch,
        cartItemToDelete,
        minimumPrice,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useCart = (): CartBag => {
  return useContext(Context);
};

export default {
  Context,
  Provider: CartProvider,
  Consumer: Context.Consumer,
};
